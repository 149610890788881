"use client";

import { SocialLinks } from "tempdev-ds";
import { useGlobalContext } from "@/lib/globalContext";

export type IBlockSocialLinks = {
  attrs: {
    anchor?: string;
    variant?: string;
    className?: string;
    style?: Record<string, unknown>;
  };
};

export default function BlockSocialLinks({ attrs }: IBlockSocialLinks) {
  const state = useGlobalContext();

  if (!state.themeOptions?.socials) return <></>

  return (
    <SocialLinks variant={attrs?.variant} socials={state.themeOptions?.socials as any} className={attrs.className} />
  );
}
