"use client"

import { useReportWebVitals } from 'next/web-vitals'
export default function WebVitals() {
  useReportWebVitals((metric) => {
    console.log(metric)
  });

  return <></>
}
