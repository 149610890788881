import(/* webpackMode: "eager" */ "/home/www-tempdev/tempdev_www/nextjs/node_modules/next/font/local/target.css?{\"path\":\"src/lib/helpers/setFont.ts\",\"import\":\"\",\"arguments\":[{\"variable\":\"--font-museo\",\"src\":[{\"path\":\"../../../public/fonts/MuseoSans/museosans_300-webfont.woff2\",\"weight\":\"300\",\"style\":\"normal\"},{\"path\":\"../../../public/fonts/MuseoSans/museosans_500-webfont.woff2\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../../../public/fonts/MuseoSans/museosans_700-webfont.woff2\",\"weight\":\"700\",\"style\":\"normal\"}]}],\"variableName\":\"font\"}");
;
import(/* webpackMode: "eager" */ "/home/www-tempdev/tempdev_www/nextjs/node_modules/next/font/local/target.css?{\"path\":\"src/lib/helpers/setFont.ts\",\"import\":\"\",\"arguments\":[{\"variable\":\"--font-scribbles\",\"src\":[{\"path\":\"../../../public/fonts/NanumPenScript/nanumpenscript-regular-webfont.woff2\",\"weight\":\"400\",\"style\":\"normal\"}]}],\"variableName\":\"scribbles\"}");
;
import(/* webpackMode: "eager" */ "/home/www-tempdev/tempdev_www/nextjs/node_modules/next/font/google/target.css?{\"path\":\"src/lib/helpers/setFont.ts\",\"import\":\"Luckiest_Guy\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-glossary\",\"weight\":[\"400\"],\"display\":\"swap\"}],\"variableName\":\"glossary\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/www-tempdev/tempdev_www/nextjs/src/components/common/Analytics/Analytics.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/www-tempdev/tempdev_www/nextjs/src/components/common/Footer/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/www-tempdev/tempdev_www/nextjs/src/components/common/Header/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/www-tempdev/tempdev_www/nextjs/src/components/common/PreviewBox/PreviewBox.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/www-tempdev/tempdev_www/nextjs/src/components/common/Twinkie/Twinkie.tsx");
;
import(/* webpackMode: "eager" */ "/home/www-tempdev/tempdev_www/nextjs/src/components/common/WebVitals/WebVitals.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GlobalContextProvider"] */ "/home/www-tempdev/tempdev_www/nextjs/src/lib/globalContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/www-tempdev/tempdev_www/nextjs/src/lib/registry.tsx");
