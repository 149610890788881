import { css, styled } from "styled-components";

import { ITwinkieWrapper } from "./Twinkie.interface";

export const StyledTwinkie = styled.div`
  position: fixed;
  bottom: 1rem;
  right: 1rem;

  display: flex;
  align-items: flex-end;

  z-index: 100;

  .no-scroll &{
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }
`;

export const StyledTwinkieWrapper = styled.div<ITwinkieWrapper>`
  position: absolute;
  right: 0;
  margin-right: 4rem;
  width: 75vw;
  max-width: 360px;
  height: 80vh;
  max-height: 80vh;

  background: white;
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.05);
  border-radius: 16px 16px 0px 16px;

  overflow: hidden;
  overflow-y: auto;

  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all 0.125s linear;

  & button{
    width: 100%;
  }

  ${({ $active }) =>
    $active &&
    css`
      visibility: visible;
      opacity: 1;
      pointer-events: auto;
    `}
`;

export const StyledTwinkieMessage = styled.div`
  position: absolute;
  bottom: 0;
  right: 100%;

  & > div {
    display: flex;
    align-items: flex-start;
    padding: 8px 16px;

    background: white;
    box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.05);
    border-radius: 16px 16px 0px 16px;

    width: 13rem;
    margin-right: 18px;

    text-align: right;
  }
`;

export const StyledTwinkieImg = styled.div`
  & img {
    width: 3.6rem;
    max-width: 3.6rem !important;
    height: 3.6rem;
    border-radius: 50%;

    cursor: pointer;

    animation-name: ripple;
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }

  @keyframes ripple {
    0% {
      box-shadow: 0 0 0 2px rgba(203, 237, 246, 0.2),
        0 0 0 4px rgba(203, 237, 246, 0.2);
    }

    35% {
      box-shadow: 0 0 0 8px rgba(203, 237, 246, 0.4),
        0 0 0 16px rgba(203, 237, 246, 0.25);
    }

    100% {
      box-shadow: 0 0 0 0px rgba(203, 237, 246, 0.2),
        0 0 0 0px rgba(203, 237, 246, 0.2);
    }
  }
`;
