// @ts-nocheck
/* eslint-disable */

"use client";

import { Heading, Icon } from "tempdev-ds";
import { StyledTwinkie, StyledTwinkieImg, StyledTwinkieMessage, StyledTwinkieWrapper } from "./Twinkie.theme";
import { useRef, useState } from "react";

import DisplayImage from "../DisplayImage/DisplayImage";
import GravityForm from "../GravityForm/GravityForm";
import { useGlobalContext } from "@/lib/globalContext";

const Twinkie = ({ form }) => {
  const state = useGlobalContext();
  const [showForm, updateShowForm] = useState(false);
  const [showMessage, updateShowMessage] = useState(true);
  const twinkieRef = useRef(null);

  return (
    <StyledTwinkie>
      <StyledTwinkieWrapper
        ref={twinkieRef}
        $active={showForm}
        data-lenis-prevent={true}
      >
        <div className={"bg-primary30 p-5"}>
          <div className={`d-flex align-items-center mb-3`}>
            <span
              className="icon-close-o"
              onClick={() => updateShowForm(false)}
            ></span>
            <Heading tag="h3" size="h6">
              {state?.dict?.custom?.twinkieTitle}
            </Heading>
          </div>

          <span className="d-block captions">{state?.dict?.custom?.twinkieMessage}</span>
        </div>

        <div className="bg-white p-5">
          {form && <GravityForm formData={form} formId={process.env.NEXT_PUBLIC_ASSISTANT_FORM_ID} />}
          <span className="captions mt-4">
            {state?.dict?.custom?.twinkieResponse}
          </span>
        </div>
      </StyledTwinkieWrapper>
      {showMessage && !showForm && (
        <StyledTwinkieMessage className={"d-none d-md-block"}>
          <div>
            <Icon
              icon="icon-close-o"
              className={`mt-1`}
              onClick={() => updateShowMessage(false)}
            ></Icon>
            <span className="d-block captions">
              {state?.dict?.custom?.twinkieDescription}
            </span>
          </div>
        </StyledTwinkieMessage>
      )}
      <StyledTwinkieImg onClick={() => updateShowForm(!showForm)}>
        <DisplayImage
          url="/assets/components/twinkie.png"
          alt="twinkie-icon"
          width={80}
          height={80}
        />
      </StyledTwinkieImg>
    </StyledTwinkie>
  );
};
export default Twinkie;
