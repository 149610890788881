// @ts-nocheck
/* eslint-disable */

"use client";

import {
  Accordion,
  AccordionItem,
  Button,
  Column,
  Dropdown,
  DropdownItem,
  Flex,
  Heading,
  Icon,
  ImageWrapper,
  RichText,
  Row,
} from "tempdev-ds";
import {
  StyledHamburger,
  StyledHeader,
  StyledMenu,
  StyledMenuItem,
} from "./Header.theme";
import { useEffect, useState } from "react";
import { useSearchParams, useSelectedLayoutSegments } from "next/navigation";

import DisplayImage from "../DisplayImage/DisplayImage";
import Link from "next/link";
import Logo from "../Logo/Logo";
import { removeScroll } from "@/lib/helpers/removeScroll";
import { useGlobalContext } from "@/lib/globalContext";

const Header = ({ recentPosts }: { recentPosts: any }) => {
  const state = useGlobalContext();
  const segments = useSelectedLayoutSegments();
  const searchParams = useSearchParams();
  const [menuActive, setMenuActive] = useState(false);
  const [searchActive, setSearchActive] = useState(false);

  useEffect(() => {
    state?.setSlug?.(segments[segments.length - 1]);
  }, [state, segments]);

  const headerOnClick = (isActive: boolean, search: boolean) => {
    removeScroll(isActive, state);

    if (search) {
      setSearchActive(isActive);
    } else {
      setMenuActive(isActive);
    }
  };

  const menuExtraButton = <></>;

  return (
    <>
      <StyledHeader id="masthead" $menuActive={menuActive}>
        <Row wrap="nowrap" align="center" style={{ position: "static" }}>
          <Column sm={12} className="d-flex align-items-center">
            <div className="flex-grow-1 flex-grow-md-0 flex-shrink-md-1">
              <Logo />
            </div>
            <div className="flex-grow-1 d-none d-md-block ps-4">
              <nav>
                <ul className="d-flex align-items-center justify-content-end">
                  {state?.menus &&
                    state?.menus[`primary-menu`]?.map((item) => {
                      const children =
                        item.menu_items && (item.menu_items as []).length > 0
                          ? item.menu_items
                          : "";
                      return (
                        <li key={`nav_menu_li_${item.id}`} className={"me-5"}>
                          {children && (children as []).length ? (
                            <Dropdown
                              title={
                                <Heading
                                  tag={"span"}
                                  size={"texts"}
                                  className="fw-semibold"
                                >
                                  {item.title}
                                </Heading>
                              }
                              icon={<Icon icon="icon-down" />}
                              fullWidth={true}
                            >
                              <DropdownItem>
                                <Row>
                                  <Column md={8} className="p-5">
                                    <Heading
                                      size="texts"
                                      tag="span"
                                      className="color-gray40 fw-semibold text-uppercase mb-4"
                                    >
                                      {item.description}
                                    </Heading>
                                    {(children as []).map(
                                      (subitem: {
                                        id: any;
                                        url: any;
                                        image: string;
                                        description: string;
                                        target: any;
                                        title: any;
                                      }) => {
                                        return (
                                          <StyledMenuItem
                                            key={`nav_menu_li_-${item.id}-${subitem.id}`}
                                            href={subitem.url}
                                            variant={"link"}
                                          >
                                            <Row align="center" fluid={true}>
                                              <Column
                                                md={4}
                                                className="d-flex align-items-center"
                                              >
                                                <div className="flex-shrink-1">
                                                  <ImageWrapper
                                                    aspectRatioHeight={100}
                                                    style={{
                                                      width: "2.6rem",
                                                    }}
                                                  >
                                                    <DisplayImage
                                                      url={
                                                        subitem?.image as string
                                                      }
                                                      alt={
                                                        subitem?.title
                                                          ? (subitem?.title as string)
                                                          : ""
                                                      }
                                                      fill={true}
                                                      style={{
                                                        objectFit: "contain",
                                                      }}
                                                    />
                                                  </ImageWrapper>
                                                </div>
                                                <div className="flex-grow-1 ps-3">
                                                  <Heading
                                                    size="h6"
                                                    tag="span"
                                                    className="fw-semibold ps-2"
                                                  >
                                                    {subitem.title}
                                                  </Heading>
                                                </div>
                                              </Column>
                                              <Column md={7}>
                                                <RichText tag="p">
                                                  {subitem.description}
                                                </RichText>
                                              </Column>
                                              <Column
                                                md={1}
                                                className="text-end"
                                              >
                                                <Icon
                                                  icon="icon-arrow-link"
                                                  className="color-primary70"
                                                />
                                              </Column>
                                            </Row>
                                          </StyledMenuItem>
                                        );
                                      }
                                    )}
                                  </Column>
                                  <Column
                                    md={4}
                                    className={"p-5"}
                                    style={{ backgroundColor: "#FAFBFB" }}
                                  >
                                    <Heading
                                      size="texts"
                                      tag="span"
                                      className="color-gray40 fw-semibold text-uppercase mb-4"
                                    >
                                      {state?.dict?.custom?.fromBlog}
                                    </Heading>
                                    {recentPosts &&
                                      recentPosts.total > 0 &&
                                      recentPosts.items.map(
                                        (blogPost: Record<string, string>) => {
                                          return (
                                            <div
                                              key={`header_blog-${item.id}-${blogPost.id}`}
                                              className="mt-6"
                                            >
                                              <RichText tag="p">
                                                {blogPost.title}
                                              </RichText>
                                              <Button
                                                variant={"link"}
                                                href={blogPost.slug}
                                                rightIcon={
                                                  <Icon className="icon-arrow-link"></Icon>
                                                }
                                                className="justify-content-start"
                                              >
                                                {state?.dict?.custom?.readArticle}
                                              </Button>
                                            </div>
                                          );
                                        }
                                      )}
                                  </Column>
                                </Row>
                              </DropdownItem>
                            </Dropdown>
                          ) : (
                            <Link
                              href={item.url}
                              target={item.target}
                              legacyBehavior
                            >
                              <Button
                                variant={"link"}
                                href={item.url}
                                target={item.target}
                                className="color-secondary90"
                                style={{ verticalAlign: "top" }}
                              >
                                {item.title}
                              </Button>
                            </Link>
                          )}
                        </li>
                      );
                    })}
                </ul>
              </nav>
            </div>
            <div className="flex-shrink-1">
              <StyledHamburger
                className="d-inline-flex d-md-none ms-3"
                onClick={() => headerOnClick(!menuActive, false)}
              >
                <span></span>
                <span></span>
                <span></span>
              </StyledHamburger>
            </div>
            <div className="flex-shrink-1 d-none d-md-block ms-4">
              {menuExtraButton}
            </div>
          </Column>
        </Row>
      </StyledHeader>
      <StyledMenu $active={menuActive} data-lenis-prevent>
        <Accordion className="w-100 h-100">
          {state?.menus &&
            state?.menus[`primary-menu`]?.map((item) => {
              const children =
                item.menu_items && (item.menu_items as []).length > 0
                  ? item.menu_items
                  : "";

              return (
                <>
                  {children && (children as []).length > 0 ? (
                    <AccordionItem
                      key={`mobile_menu_li_${item.id}`}
                      title={item.title}
                      style={{
                        border: 0,
                        borderBottom: "1px solid",
                        paddingLeft: 0,
                        paddingRight: 0,
                        borderRadius: 0,
                        marginBottom: 0,
                      }}
                    >
                      <Flex direction="column" align="start" justify={"start"}>
                        {(children as []).map(
                          (subitem: {
                            id: any;
                            url: any;
                            target: any;
                            title: any;
                          }) => {
                            return (
                              <div
                                key={`mobile_menu_li_-${item.id}-${subitem.id}`}
                                className="d-flex align-items-center mt-3"
                              >
                                <ImageWrapper
                                  aspectRatioHeight={100}
                                  style={{ width: "2.6rem" }}
                                >
                                  <DisplayImage
                                    url={subitem?.image as string}
                                    alt={
                                      subitem?.title
                                        ? (subitem?.title as string)
                                        : ""
                                    }
                                    fill={true}
                                    style={{
                                      objectFit: "contain",
                                    }}
                                  />
                                </ImageWrapper>
                                <Link
                                  href={subitem.url}
                                  target={subitem.target}
                                  legacyBehavior
                                >
                                  <Button
                                    variant={"link"}
                                    href={subitem.url}
                                    target={subitem.target}
                                    className={"color-secondary90 ps-3"}
                                    style={{ fontWeight: 400 }}
                                    onClick={() => headerOnClick(false, false)}
                                  >
                                    {subitem.title}
                                  </Button>
                                </Link>
                              </div>
                            );
                          }
                        )}
                      </Flex>
                    </AccordionItem>
                  ) : (
                    <div
                      key={`mobile_menu_li_-${item.id}`}
                      className=""
                      style={{
                        padding: "1.6rem 0",
                        borderBottom: "1px solid",
                      }}
                    >
                      <Link href={item.url} target={item.target} legacyBehavior>
                        <Button
                          variant={"link"}
                          href={item.url}
                          target={item.target}
                          className={"color-secondary90 h6 fw-regular"}
                          onClick={() => headerOnClick(false, false)}
                        >
                          {item.title}
                        </Button>
                      </Link>
                    </div>
                  )}
                </>
              );
            })}
        </Accordion>
        <Flex
          direction="column"
          align="center"
          justify={"center"}
          className="mt-5"
          style={{ gap: "16px" }}
        >
          {menuExtraButton}
        </Flex>
      </StyledMenu>
    </>
  );
};

export default Header;
