"use client";

import Link from "next/link";
import { styled } from "styled-components";
import { variables } from "tempdev-ds";

export const StyledPreviewBox = styled.div`
  position: fixed;
  top: 4rem;
  left: 50%;

  padding: 12px 22px;

  background: ${variables.colors.white};
  border: 1px solid ${variables.colors.primary10};
  box-shadow: ${variables.shadows.md};
  border-radius: 50px;

  transform: translateX(-50%);
  z-index: 10000;
`;

const PreviewBox = () => {
  return (
    <StyledPreviewBox>
      This page is a preview. {" "}
      <Link
        href="/api/preview-exit/"
        prefetch={false}
        className="color-primary70"
      >Exit preview mode</Link>
    </StyledPreviewBox>
  );
};
export default PreviewBox;
