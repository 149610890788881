import Image from "next/image";
import Link from "next/link";
import { styled } from "styled-components";
import { variables } from "tempdev-ds";

export const StyledLogo = styled.div`
  width: 10rem;

  @media only screen and (min-width: ${variables.responsiveMedia}) {
    width: 16rem;
  }
`;

const Logo = () => {
  return (
    <StyledLogo>
      <Link href={"/"} as={"/"} prefetch={false}>
        <Image
          src={"/assets/logo.svg"}
          alt={process.env.NEXT_PUBLIC_SITE_NAME as string}
          priority={true}
          width={130}
          height={35}
        />
      </Link>
    </StyledLogo>
  );
};
export default Logo;
