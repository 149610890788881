"use client";

import { Button, Column, Heading, Icon, Row } from "tempdev-ds";

import BlockSocialLinks from "@/components/blocks/miewtheme/BlockSocialLinks/BlockSocialLinks";
import { Flex } from "tempdev-ds";
import Image from "next/image";
import Link from "next/link";
import { useGlobalContext } from "@/lib/globalContext";

const Footer = ({ recentPosts }: { recentPosts: any }) => {
  const state = useGlobalContext();
  return (
    <>
      <footer id="site-footer" className="pt-6 pb-4">
        <Row>
          <Column md={2} className="pt-5">
            <Image
              src={"/assets/logo-footer.svg"}
              alt={process.env.NEXT_PUBLIC_SITE_NAME as string}
              priority={true}
              width={110}
              height={94}
            />
          </Column>
          {state?.menus &&
            state?.menus[`footer-menu`]?.map((item) => {
              const children =
                item.menu_items && (item.menu_items as []).length > 0
                  ? item.menu_items
                  : "";
              return (
                <Column
                  key={`footer-cell_${item.id}`}
                  xs={6}
                  md={2}
                  className="pt-5"
                >
                  <Heading tag={"span"} size={"h6"} className="fw-bold mb-1">
                    {item.title as string}
                  </Heading>

                  {children &&
                    (children as []).length &&
                    (children as []).map((subitem: Record<string, string>) => {
                      return (
                        <Link
                          key={`footer-link_${subitem.id}`}
                          href={subitem.url as string}
                          target={subitem.target as string}
                        >
                          <Button
                            variant={"link"}
                            className={"d-table color-secondary90 fw-light mt-1 text-start"}
                          >
                            {subitem.title}
                          </Button>
                        </Link>
                      );
                    })}
                </Column>
              );
            })}
          <Column xs={6} md={4} className="pt-5">
            <Heading tag={"span"} size={"h6"} className="fw-bold mb-1">
              {state?.dict?.postTypes?.blog}
            </Heading>
            {recentPosts &&
              recentPosts.total > 0 &&
              recentPosts.items.map((item: Record<string, string>) => {
                return (
                  <Link
                    key={`footer-link_${item.id}`}
                    href={item.slug as string}
                  >
                    <Button
                      variant={"link"}
                      className={"d-table color-secondary90 fw-light mt-2 text-start"}
                    >
                      {item.title}
                    </Button>
                  </Link>
                );
              })}
          </Column>
        </Row>
        <div className="pt-7 pb-5">
          <Row align="center" mobileSpace={true}>
            <Column md={8} mobileOrder={2} className="d-flex align-items-center">
              <Heading tag={"span"} size={"captions"} className="color-gray50 me-4">
                © 2023 TempDev Inc.
              </Heading>
              {state?.menus &&
                state?.menus[`footer-terms`]?.map((item) => {
                  return (
                    <Link
                      key={`footer-term-${item.id}`}
                      href={item.url as string}
                      target={item.target as string}
                    >
                      <Button
                        variant={"link"}
                        className={"color-secondary90"}
                        style={{ fontWeight: 400 }}
                      >
                        {item.title as string}
                      </Button>
                    </Link>
                  );
                })}
            </Column>
            <Column md={4} mobileOrder={1}>
              <Flex className="gap-4">
                <div className="flex-shrink">
                  <Heading tag={"span"} size={"h6"} className="fw-bold">
                    {state?.dict?.custom?.followUs}
                  </Heading>
                </div>
                <div className="flex-grow-1">
                  <BlockSocialLinks attrs={{}} />
                </div>
              </Flex>
            </Column>
          </Row>
        </div>
      </footer>
    </>
  );
};

export default Footer;
