import { IHeader, IMenuMobile } from "./Header.interface";
import styled, { css } from "styled-components";

import { Button } from "tempdev-ds";

export const StyledHeader = styled.div<IHeader>`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;

  box-shadow: var(--shadow-sm);
  background-color: white;
  padding: 20px 0;
  z-index: 100;

  & ul,
  li {
    padding: 0;
    list-style: none;

    &:last-child {
      margin-right: 0;
    }
  }

  ${({ $menuActive }) =>
    $menuActive &&
    css`
      ${StyledHamburger} {
        span {
          &:nth-of-type(1) {
            transform-origin: bottom;
            transform: rotatez(45deg) translate(16px, 0px);
          }

          &:nth-of-type(2) {
            transform-origin: top;
            transform: rotatez(-45deg);
          }

          &:nth-of-type(3) {
            width: 50%;
            transform-origin: bottom;
            transform: translate(2px, -13px) rotatez(45deg);
          }
        }
      }
    `}
`;

export const StyledHamburger = styled.div`
  position: relative;
  vertical-align: middle;
  flex-direction: column;
  justify-content: center;

  width: 24px;

  color: var(--color-secondary90);
  cursor: pointer;

  span {
    background: currentColor;
    border-radius: 6px;
    height: 2px;
    margin: 3px 0;
    transition: 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6);

    &:nth-of-type(1) {
      width: 50%;
    }

    &:nth-of-type(2) {
      width: 100%;
    }

    &:nth-of-type(3) {
      width: 75%;
    }
  }
`;

export const StyledMenu = styled.div<IMenuMobile>`
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
  padding: 1rem;
  padding-top: 6rem;
  background-color: white;
  transform: translateX(100%);
  transition: all 0.3s linear;

  overflow: auto;
  z-index: 10;

  ${({ $active }) =>
    $active &&
    `
    transform: translateX(0%);
  `}
`;

export const StyledSearch = styled.div<IMenuMobile>`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.6);
  transition: all 0.3s linear;
  z-index: 10;

  & > div {
    display: flex;
    flex-flow: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    max-height: 25rem;
    padding: 4rem 0;
    padding-top: 6rem;
    background-color: white;
    transform: translateY(-100%);
    overflow-x: hidden;
    overflow-y: auto;
    transition: all 0.3s linear;
    transition-delay: 0.3s;
  }

  ${({ $active }) =>
    $active &&
    `
    opacity:1;
    visibility: visible;
    pointer-events: all;

    & > div{
      transform: translateY(0%);
    }
  `}
`;

export const StyledMenuItem = styled(Button)`
  position: relative;
  display: block;

  margin: 0;

  color: inherit;
  border-radius: var(--radius-md);
  transition: all 0.3s linear;

  & > span {
    display: block;
    padding: 1.6rem;
  }

  &:hover {
    background-color: var(--color-primary10);
  }
`;
