import { createGlobalStyle } from 'styled-components';
import { variables } from 'tempdev-ds';

const CustomStyles = createGlobalStyle`
  body {
    overflow: unset !important;

    b,
    strong,
    .fw-bold{
      font-weight: 600 !important;
    }

    h1, h2, h3, h4, h5, h6 {
      font-weight: 600;
    }

    p{
      font-weight: 300 !important;
    }
  }

  .alternative-font,
  .font-scribbles{
    font-family: var(--font-scribbles), cursive;
    font-weight: 400 !important;
    text-transform: uppercase;
  }

  .font-glossary{
    font-family: var(--font-glossary);
    font-weight: 400 !important;
    margin-top: 16px;
  }

  .sticky-element{
    position:sticky;
    top:80px;
  }

  .collaborators-boxed{
    position: absolute !important;
    right: 0;
    bottom: 0;

    display: flex;
    flex-flow: column;
    align-items: flex-end;

    max-width: 13rem;
    text-align: right;
    z-index: 200;

    & > div{
      background: white;
      border-radius: 16px 16px 0 16px;
      box-shadow: 0 2px 24px rgba(0,0,0,.05);
      margin-bottom: 1.2rem;
      padding: 1rem;
      text-align: right;

      & p{
        font-size: 12px;
      }
    }
  }

  .nav-container{
    padding: 0;
    margin:0;
    list-style: none;
  }

  .rotated-title {
    transform: rotate(-3deg);
  }

  .rounded-section{
    border-radius: 1rem;
    padding: 2rem !important;

    @media only screen and (min-width: ${variables.responsiveMedia}) {
      padding: 4.4rem !important;
      border-radius: 2rem;
    }
  }

  .white-cards{
    .block-group{
      width: 46%;
      margin: 16px 2%;
      max-width: 195px;

      &:last-of-type{
        transform: rotate(-5deg);
      }
    }
  }

  .wp-image{
    border-radius: var(--radius-md);
    overflow:hidden;
  }

  .home-intro{
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    @media only screen and (min-width: ${variables.responsiveMedia}) {
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 60%;
        height: 100%;

        background-image:url('/assets/components/home-hero.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        pointer-events: none;
        z-index: -1
      }
    }
  }
  .home-solution-lbl{
    position: absolute;
    top: -10%;
    right: 5%;

    align-items: flex-start;
    margin: auto;

    transform: rotate(-6deg);

    & img{
      max-width: 4rem;
      order: 2;
    }
  }
  .home-cells {
    z-index: 2;
    .block-group{
      background: #fff;
      border: 1px solid #dcdfe5;
    }
    &+.rounded-section {
      margin-top: -22rem;
      padding-top: 26rem !important;
    }
  }

  .share-holder{
    position:relative;

    &:before {
      content: "";
      position: absolute;
      top: -5%;
      left: 0;
      width: 100%;
      height: 4rem;

      background-image: url("/assets/components/share-stars.svg");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;

      pointer-events: none;
    }
  }

  .services-anchors{
    min-width:280px;
  }


  //custom selects
  .custom-select{
    position: relative;
    display: inline-block;
    vertical-align: top;
    text-align: left;
    min-width: 180px;

    & > span {
      position: absolute;
      top: 12px;
      left: 12px;
      pointer-events: none;

      display: block;
      font-weight:bold;
    }

    & input,
    & select {
      width: 100%;
      color: #576075;

      border: 1px solid #7e89a0 !important;
      border-radius: 8px;
      outline: 0 !important;
      background: white !important;
      padding: 40px 34px 12px 12px;
      cursor: pointer;
    }

    i {
      position: absolute;
      bottom: 20px;
      right: 12px;

      font-size: 12px;
      pointer-events: none;
    }
    option{
      font-weight: normal;
      display: block;
      white-space-collapse: collapse;
      text-wrap: nowrap;
      min-height: 1.2em;
      padding: 0px 4px 2px;

    }
  }


  //custom animations
  kbd.will-appear,
  s.will-appear {
    position: relative;

    & svg {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;

      max-width: unset;
      pointer-events: none;

      & path {
        stroke-dasharray: 279;
        stroke-dashoffset: 279;

        transition: all 0.6s linear;
      }
    }

    &.appeared {
      & svg {
        & path {
          stroke-dashoffset: 0 !important;
        }
      }
    }
  }
  kbd.will-appear {
    svg{
      width: 110%;
      top: 50%;
      left: -5%;

      transform: translateY(-50%);

      & path {
        stroke-dasharray: 613;
        stroke-dashoffset: 613;
      }
    }
  }

  //forms
  .grecaptcha-badge{
    display: none !important;
  }

  //animations
  /* .elements_animated {
    position: relative;
    opacity: 0;
    pointer-events: none;

    will-change: transform, opacity;

    &.top,
    &.top-t-bottom {
      transform: translateY(-80px);

      &.in-viewport {
        transform: translateY(0px);
      }
    }
    &.bottom,
    &.bottom-t-top {
      transform: translateY(80px);

      &.in-viewport {
        transform: translateY(0px);
      }
    }
    &.left,
    &.left-t-right {
      transform: translateX(-80px);

      &.in-viewport {
        transform: translateX(0px);
      }
    }
    &.right,
    &.right-t-left {
      transform: translateX(80px);

      &.in-viewport {
        transform: translateX(0px);
      }
    }

    &.scale {
      transform: scale(0.8);

      &.in-viewport {
        transform: scale(1);
      }
    }

    &.masked {
      opacity: 0;
      transition-delay: 1s;
      transition: opacity 0.175s ease;
    }

    &.in-viewport {
      opacity: 1;
    }

    transition: all 0.4s ease-in-out;
  }
  .elements_animated_mask {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background-color: $white;
    transform-origin: 100% 0;
    z-index: 15;
    transition: transform 1s ease-in-out;

    .in-viewport & {
      transform: scaleX(0);
    }
  } */


  //Smooth scroll
  html.lenis {
    height: auto;
  }

  .lenis.lenis-smooth {
    scroll-behavior: auto;
  }

  .lenis.lenis-smooth [data-lenis-prevent] {
    overscroll-behavior: contain;
  }

  .lenis.lenis-stopped,
  .lenis.no-scroll {
    overflow: hidden;
  }

  .lenis.lenis-scrolling iframe {
    pointer-events: none;
  }
` as any;


export default CustomStyles;
